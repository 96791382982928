import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
  showPassword = false;
  @Input() set errorMessage(message: string) {
    if (this.loginForm && message && message.trim().length) {
      this.loginForm.setErrors({ invalid: true, message });
    }
  }
  @Output() loginClicked = new EventEmitter<{
    email: string;
    password: string;
  }>();
  @Output() forgotClicked = new EventEmitter<boolean>();
  @Output() singupModeClicked = new EventEmitter<boolean>();
  loginForm: FormGroup;

  constructor(private fb: FormBuilder, private session: SessionService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.minLength(8)]],
    });
  }
  googleLogin: boolean = false;

  loading$ = this.session.loading$;

  get email() {
    return this.loginForm.get('email') as FormControl;
  }

  get password() {
    return this.loginForm.get('password') as FormControl;
  }

  get emailErrorMessage() {
    if (this.email.hasError('required')) return 'Email is required';
    if (this.email.hasError('email')) return 'Please provide a valid email';
    return '';
  }
  get passwordErrorMessage() {
    if (this.password.hasError('required')) return 'Password is required';
    if (this.password.hasError('minlength'))
      return 'Password must be minimum 8 characters long';
    return '';
  }

  get formErrorMessage() {
    if (
      this.loginForm.hasError('invalid') &&
      this.loginForm.hasError('message')
    ) {
      return this.loginForm.errors['message'];
    }
  }

  get fieldsValid() {
    return this.email.valid && this.password.valid;
  }

  signInUsingGoogle() {
    this.session.setLoading(true);
    this.googleLogin = true;
    this.session.googleSocialSignIn();
  }

  login() {
    if (!this.fieldsValid) return;
    this.loginClicked.emit({
      email: this.email.value,
      password: this.password.value,
    });
  }

  forgotPassword() {
    this.forgotClicked.emit(true);
  }
  // signupClicked() {
  //   this.singupModeClicked.emit(true);
  // }
}

import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { passwordMatchValidator } from '@core/utils/validator-utils';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordFormComponent {
  showPassword = false;
  changePasswordForm: FormGroup;
  @Input() set errorMessage(message: string) {
    if (this.changePasswordForm && message && message.trim().length) {
      this.changePasswordForm.setErrors({ invalid: true, message });
    }
  }
  @Output() changePasswordClicked = new EventEmitter<{
    password: string;
  }>();

  constructor(private fb: FormBuilder, private session: SessionService) {
    this.changePasswordForm = this.fb.group(
      {
        newPassword: ['', [Validators.minLength(8)]],
        confirmPassword: ['', [Validators.minLength(8)]],
      },
      { validators: passwordMatchValidator }
    );
  }

  loading$ = this.session.loading$;

  get newPassword() {
    return this.changePasswordForm.get('newPassword') as FormControl;
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword') as FormControl;
  }

  get newPasswordErrorMessage() {
    if (this.newPassword.hasError('required')) return 'Password is required';
    if (this.newPassword.hasError('minlength'))
      return 'Password must be minimum 8 characters long';
    return '';
  }
  get confirmPasswordErrorMessage() {
    if (this.confirmPassword.hasError('required'))
      return 'Password is required';
    if (this.newPassword.hasError('passwordMatch'))
      return `Passwords don't match`;
    return '';
  }

  get formErrorMessage() {
    if (
      this.changePasswordForm.hasError('invalid') &&
      this.changePasswordForm.hasError('message')
    ) {
      return this.changePasswordForm.errors['message'];
    }
    if (this.changePasswordForm.hasError('passwordMatch'))
      return `Passwords don't match`;
  }

  changePassword() {
    if (!this.changePasswordForm.valid) return;
    this.changePasswordClicked.emit({
      password: this.newPassword.value,
    });
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent {
  showPassword = false;
  @Input() set errorMessage(message: string) {
    if (this.forgotPasswordForm && message && message.trim().length) {
      this.forgotPasswordForm.setErrors({ invalid: true, message });
    }
  }
  @Output() forgotPasswordClicked = new EventEmitter<{
    email: string;
  }>();
  @Output() loginClicked = new EventEmitter<boolean>();
  @Output() bypassForgotPasswordClicked = new EventEmitter<boolean>();
  forgotPasswordForm: FormGroup;

  constructor(private fb: FormBuilder, private session: SessionService) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  loading$ = this.session.loading$;

  get email() {
    return this.forgotPasswordForm.get('email') as FormControl;
  }

  get emailErrorMessage() {
    if (this.email.hasError('required')) return 'Email is required';
    if (this.email.hasError('email')) return 'Please provide a valid email';
    return '';
  }

  get formErrorMessage() {
    if (
      this.forgotPasswordForm.hasError('invalid') &&
      this.forgotPasswordForm.hasError('message')
    ) {
      return this.forgotPasswordForm.errors['message'];
    }
  }

  bypassForgotPassword() {
    this.bypassForgotPasswordClicked.emit(true);
  }

  forgotPassword() {
    if (!this.forgotPasswordForm.valid) return;
    this.forgotPasswordClicked.emit({
      email: this.email.value,
    });
  }

  backToLogin() {
    this.loginClicked.emit(true);
  }
}
